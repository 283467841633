
.contact-wrapper {
    color: #fff;
    text-align: center;
    padding: 5%;
    background-color: #0c0e1b;
    position: relative;
}

.contact-wrapper hr {
    max-width: 20%;
}

.contact-container {
    margin: 4% 0%;
    position: relative;
    align-content: center; 
}

.contact-container h2 {

    font-size: x-large;
    font-weight: 600;
    margin-bottom: 5%;
}

.contact-content {

    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
    gap: 3rem;
    
}
.contact-form {
    margin-left: 25%;
    width: 80%;

}