
.contact-details-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0c0e1b;
    border-radius: 0.65rem;
    border: 1px solid #0472d8;
    padding: 1.7rem;
    margin-bottom: 2rem;
}

.contact-details-card .icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: #0c0e1b;
}

.icon img {

    width: 4rem;
    height: auto;
    object-fit: contain;
}

.contact-details-card a {
    font-size: 0.9rem;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    padding: 0 3px;
}

.contact-details-card a:hover {
    color: #0472d8;
}

.contact-details-card a::after {
    color: #3d3d3d;
}