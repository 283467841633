
.nav-wrapper {

    background-color: #363636; 
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);
}

.nav-content {
    
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;

}

.logo {

    width: 12rem;
    height: auto;

}

.nav-content ul {

    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;

}

.nav-content li {
    margin: 0 1.5rem;

}

.menu-title {

    text-decoration: solid;
    font-size: 1.3rem;
    font-weight: 700;
    color: #0472d8;
    position: relative;
}

.menu-item {

    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    position: relative;
    cursor: pointer;

}

.menu-item::before {

    content: "";
    width: 2rem;
    height: 0.2rem;
    background: linear-gradient(90deg, #2e87da 0%, #0472d8 100%);
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.6rem;
    opacity: 0;
    transform: translateX(-1.5rem);
    transition: all 0.3s ease;

}

.menu-item:hover::before {

    width: 100%;
    transform: translateX(0);
    opacity: 1;
}

.link {
    text-decoration: none;
}

.contact-btn {
    
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(90deg, #2e87da 0%, #0472d8 100%);
    padding: 0.6rem 2rem;
    border: none;
    outline: 1.5px solid transparent;
    border-radius: 0.2rem;
    cursor: pointer;
    transition: all 0.3 ease;

}

.contact-btn:hover {

    color: #fff;
    background: #030303;
    outline: 1.5px solid #2e87da;

}

.menu-btn {

    width: 2.2rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    color: #050505;
    background: linear-gradient(90deg, #2e87da -0.13%, #0472d8 99.87%);
    line-height: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    display: none;

}

.menu-btn:hover {
    color: #2e87da;
    background: #000;
    border: 1px solid #2e87da;

}

@media (max-width: 769px) {

    .menu-btn {
        display: block;
    }

    .nav-content ul {
        display: none;
    }
}

@media (max-width: 1325px) {
   
    .nav-wrapper {
        padding: 0 2rem;
        
    }
}