body {
    background-color: #0c0e1b;
}
.education-wrapper {
    width: auto;
    height: auto;
    color: #fff;
    text-align: center;
    padding: 5%;
}

.education-title {

    font-size: xxx-large;
    color: #fff;
    background-color: #0c0e1b;
}

.education-wrapper hr {
    
    width: 30%;
}

.education-content {
    
    display: inline-flex;
    text-align: center;
    align-items: center;
}

.education-content ul {

    display: inline-block;
    font-weight: bolder;
    list-style: none;
    text-align: left;
}

.education-content p {

    color: #3f3f41;
    font-style: italic;
    font-weight: 400;
    margin-top: 0%;
}

.education-content-title {

    text-align: center;
    font-size: x-large;

}

.education-content-title hr {

    width: 15%;
}

a {
    color: white;
    
}

a:hover {
    color: #0202fd;
}

a::after {
    color: grey;
}