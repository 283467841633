
.about-wrapper {
    color: #fff;
    text-align: center;
    padding: 5%;
    background-color: #0c0e1b;
    position: relative;
    padding-bottom: 3px;
}

.about-title {

    font-size: xxx-large;
    color: #fff;
    text-align: center;
    background-color: #0c0e1b;
    position: relative;
}

.about-wrapper hr {
    width: 30%;
}

.about-content {
    text-align: left;
    width: 60%;
    padding: 5%;
    margin-left: auto;
    margin-right: auto;
}

.about-content a {
    color: white;
}

.about-content a::after {
    color: grey;
}

.about-content blockquote {
    font-weight: 500;
    font-style: oblique;
}

.picture {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 325px;
    height: 400px;
    border: 4px solid black;
}

@media (max-width: 769px) {
    .about-content {
        width: auto;
    }
}