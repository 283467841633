.contact-form-content {
    width: 60%;
    
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.name-container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
form input,
form textarea {

    flex: 1;
    width: 100%;
    font-size: 0.9rem;
    color: #fff;
    background: #363636;
    border-radius: 0.6rem;
    border: 1.5px solid #0472d8;
    padding: 1rem;
}

form button {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border: 1.5px solid #363636;
    border-radius: 0.6rem;
    background: #0472d8;
    padding: 0.7rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

form button:hover {
    background: #000;
}

@media (max-width: 760px) {
    
    .contact-content {
        flex-direction: column-reverse;
    }

    .contact-container h2 {
        font-size: 1.3rem;
        margin-bottom: 0rem;

    }

    .name-container {
        display: block;
    }

    .name-container input {
        margin: 10px 0;
    }
}